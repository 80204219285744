import { Box } from "@mui/material";
import { graphql } from "../../../api/__generated__";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import Header from "./Common/Header";
import StepHeader from "./Common/StepHeader";
import Footer from "./Common/Footer";
import StepMainContent from "./Common/StepMainContent";
import BloomieCard from "./Common/BloomieCard";

const selectedTopicQuery = graphql(`
  query SelectedTopic {
    selectedTopic {
      descriptionTranslationKey
      id
      imageUrl
      subtopics {
        descriptionTranslationKey
        id
        imageUrl
        titleTranslationKey
      }
      titleTranslationKey
    }
  }
`);

const submitSubtopicStepMutation = graphql(`
  mutation SubmitSubtopicStep($subtopicId: String) {
    submitSubtopicStep(subtopicId: $subtopicId) {
      matchingToken
      nextStep
    }
  }
`);

interface SubTopicStepProps {
  setNextStep: (nextStep: string) => void;
  stepBack: () => void;
}

const SubtopicStep = ({ setNextStep, stepBack }: SubTopicStepProps) => {
  const { t } = useTranslation();
  const { data: topicData } = useQuery(selectedTopicQuery, {
    fetchPolicy: "network-only",
  });

  const [submitSubtopicStep] = useMutation(submitSubtopicStepMutation);
  const [selectedSubtopicId, setSelectedSubtopicId] = useState<string | null>();

  return (
    <Box sx={{ height: "100%", textAlign: "center", width: "100%" }}>
      <Header />
      <StepHeader
        imageUrl={topicData?.selectedTopic?.imageUrl || ""}
        subtitle={t(topicData?.selectedTopic?.descriptionTranslationKey || "")}
        title={t(topicData?.selectedTopic?.titleTranslationKey || "")}
      />
      <StepMainContent
        enableNext={selectedSubtopicId !== undefined}
        onClickNext={async () => {
          const { data: submitData } = await submitSubtopicStep({
            variables: {
              subtopicId: selectedSubtopicId,
            },
          });

          submitData?.submitSubtopicStep.nextStep &&
            setNextStep(submitData.submitSubtopicStep.nextStep);

          submitData?.submitSubtopicStep.matchingToken &&
            localStorage.setItem(
              "matchingToken",
              submitData.submitSubtopicStep.matchingToken,
            );
        }}
        onClickPrevious={stepBack}
      >
        <Box
          sx={{
            alignItems: "baseline",
            display: "flex",
            flexWrap: "wrap",
            gap: "35px 62px",
            justifyContent: "center",
            maxWidth: "1100px",
            paddingY: "1rem",
          }}
        >
          {topicData?.selectedTopic?.subtopics.map((subTopic) => (
            <BloomieCard
              bodyText={t(subTopic.descriptionTranslationKey)}
              checked={subTopic.id === selectedSubtopicId}
              imgUrl={subTopic.imageUrl}
              onClick={() => {
                setSelectedSubtopicId(subTopic.id);
              }}
              title={t(subTopic.titleTranslationKey)}
              type="outlined"
            />
          ))}
          <BloomieCard
            bodyText={t("topics:something_else.description")}
            checked={selectedSubtopicId === null}
            imgUrl={
              "https://production-guidedgrowthtracks.s3.eu-central-1.amazonaws.com/shared/plants/ID%3D63-dont-know%2C+Size%3DSmall.png"
            }
            key={0}
            onClick={() => {
              setSelectedSubtopicId(null);
            }}
            title={t("topics:something_else.title")}
            type="outlined"
          />
        </Box>
      </StepMainContent>
      <Footer />
    </Box>
  );
};

export default SubtopicStep;
