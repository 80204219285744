/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import useCreatePermanentHuman from "../../../../api/humans/hooks/useCreatePermanentHuman";
import useUpdateHumanMutation from "../../../../api/humans/hooks/useUpdateHumanMutation";
import useUpdateMatchedHuman from "../../../../api/matchedHumans/hooks/useUpdateMatchedHuman";
import useUpdateMatchedHumanWorries, {
  IUpdateMatchedHumanWorriesInput,
} from "../../../../api/matchedHumans/hooks/useUpdateMatchedHumanWorries";
import useFindMatches, {
  IMatch,
} from "../../../../api/matching/hooks/useFindMatches";
import useStartMatching from "../../../../api/matching/hooks/useStartMatching";
import useFetchAllSpecialties from "../../../../api/specialities/hooks/useFetchAllSpecialties";
import { getGenericErrorMessage } from "../../../../constants";
import HealthInsurance from "../../../../data-model/types/reimbursement/HealthInsurance";
import { loadAndValidateMatchingToken } from "../../../../utils/auth";
import useStorage from "../../../hooks/useStorage";
import { BloomUpNamespaces } from "../../../language/I18Namespaces";
import { prepareOneLanguageForAPI } from "../../../language/languagesUtil";
import LoadingPage from "../../../layout/LoadingPage";
import { IAuthContext } from "../../../providers/auth/auth";
import AuthContext from "../../../providers/auth/AuthContext";
import { IToastContext } from "../../../providers/toast/toast";
import ToastContext from "../../../providers/toast/ToastContext";
import { RouteNames } from "../../../routes/routeNames";
import useHumanMatchingFormState from "../hooks/useHumanMatchingFormState";
import { graphql } from "../../../../api/__generated__";
import { UUID } from "../../../../api/api";
import { APIMatchedHuman } from "../../../../api/matchedHumans/matchedHumans";
import { ConsultationTypes } from "../../../../data-model/types/consultation/Consultation";
import ConsultationStatus from "../../../../data-model/types/consultation/ConsultationStatus";
import TimeSlot from "../../../../data-model/types/consultation/TimeSlot";
import Password from "../../../../data-model/types/profile/Password";
import Timezone from "../../../../data-model/types/profile/Timezone";
import Worries from "../../../../data-model/types/profile/Worries";
import Organization from "../../../../data-model/types/Organization";
import Professional from "../../../../data-model/types/professional/Professional";
import Email from "../../../../data-model/types/profile/Email";
import { getStepByNumber, STEPS } from "./humanMatchingSteps";
import HumanMatchingContext from "./HumanMatchingContext";
import { IHumanMatchingContext, Step } from "./humanMatching";

export const KEY_CHOSEN_PROFESSIONAL = "chosen_professional";

const KEY_CONSULTATION_UUID = "consultation_id";

const getStepsInProgressBar = () => {
  const steps = STEPS.filter((s: Step) => s.includeInStepsCount);

  return steps ? steps.length : 0;
};

const totalSteps = STEPS.length;

export default function HumanMatchingStateProvider({
  children,
}: IHumanMatchingContext) {
  const mounted = useRef(false);
  const navigate = useNavigate();
  const {
    t: translate,
    i18n,
    ready,
  } = useTranslation<BloomUpNamespaces>("errors");

  const {
    getWorriesFromStorage,
    persistMatchingTokenToStorage,
    persistWithKeyToStorage,
    removeMatchingTokenFromStorage,
  } = useStorage();

  const [fromPartnerPage, setFromPartnerPage] = useState<boolean | undefined>(
    undefined,
  );

  useLayoutEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  // CONTEXT
  const { internalAppLogin: login, loading: fetchingMe } =
    useContext<IAuthContext>(AuthContext);

  const { setToast } = useContext<IToastContext>(ToastContext);

  // STATE
  const formState = useHumanMatchingFormState();
  const [currentStep, setCurrentStep] = useState<Step>(STEPS[0]);
  const [selectedMatch, _setSelectedMatch] = useState<IMatch | undefined>(
    undefined,
  );

  const [selectedTimeSlot, setSelectedTimeSlot] = useState<
    TimeSlot | undefined
  >(undefined);

  const [organization, setOrganization] = useState<Organization | undefined>();

  //Mutations
  const CONSULTATION_CREATE = graphql(`
    mutation createIntro($input: CreateConsultationInput!) {
      createConsultation(input: $input) {
        id
        uuid
      }
    }
  `);

  //HOOKS
  const { specialties, loading: isFetchingSpecialties } =
    useFetchAllSpecialties();

  const { updateMatchedHumanWorries, loading: isUpdatingMatchedHumanWorries } =
    useUpdateMatchedHumanWorries();

  const { updateMatchedHuman, loading: isUpdatingHuman } =
    useUpdateMatchedHuman();

  const [createConsultation, { loading: isCreatingConsult }] =
    useMutation(CONSULTATION_CREATE);

  const { createPermanentHuman } = useCreatePermanentHuman();
  const { updateHuman } = useUpdateHumanMutation();
  const { startMatching, data: startMatchingData } = useStartMatching();
  const { findMatches, matches, isMatching } = useFindMatches();

  const formIsValid = (path: string) => formState.validate(path);
  // if no validationPath is given, go on without validation
  const stepIsValid = useCallback(
    ({ validationPath }: Step) => {
      if (validationPath instanceof Array) {
        return validationPath.reduce((acc: boolean, current: any) => {
          return acc && formState.validate(current);
        }, true);
      }

      return validationPath ? formState.validate(validationPath) : true;
    },
    [formState.validate],
  );

  useEffect(() => {
    const updateLanguageIfStarted = async () => {
      const matchingToken = await loadAndValidateMatchingToken();

      if (matchingToken === null) {
        if (window.location.href.includes(RouteNames.Matching.path)) {
          navigate(RouteNames.Matching.Start.path);
        }
      } else {
        if (mounted.current) {
          await updateMatchedHuman({
            language: prepareOneLanguageForAPI(i18n.language),
          });
        }
      }
    };

    // noinspection JSIgnoredPromiseFromCall
    updateLanguageIfStarted();
  }, [i18n.language]);

  const loadStoredWorriesInFormState = async () => {
    const worries = await getWorriesFromStorage();

    formState.setValue(Worries.getPath(), worries);
  };

  // This is a 'standard' function to lineary progress through the steps defined in
  // './humanMatchingSteps.ts', but individual step components also implement
  // custom routing based on the presence of an accessToken
  const next = useCallback(
    (currentStepStateOverride?: Step) => {
      const thisStep = currentStepStateOverride ?? currentStep;
      const nextIndex = thisStep.step + 1;

      if (stepIsValid(thisStep)) {
        if (nextIndex < totalSteps) {
          const nextStep = STEPS[nextIndex];

          if (nextStep.route) {
            navigate(nextStep.route);
          }
        }
      }
    },
    [currentStep, history, stepIsValid],
  );

  const previous = useCallback(() => {
    const previousIndex = currentStep.step - 1;

    if (previousIndex >= 0) {
      const previousStep = getStepByNumber(previousIndex);

      if (previousStep && previousStep.route) {
        setCurrentStep(previousStep);
        navigate(previousStep.route);
      }
    } else {
      navigate(RouteNames.Matching.Start.path);
      setCurrentStep(STEPS[0]);
    }
  }, [currentStep, setCurrentStep, history]);

  const executeFindMatches = useCallback(async () => {
    if (formState) {
      let worries = formState.getInputProps(Worries.getPath()).value;

      if (!worries || (Array.isArray(worries) && worries.length === 0)) {
        worries = await getWorriesFromStorage();
      }

      findMatches();
    }
  }, [formState, findMatches]);

  const fetchAndStoreMatchingToken = async (
    slug?: string,
    extraInfo?: string,
  ) => {
    const startedMatch = await startMatching({
      extraInfo,
      slug,
    });

    if (startedMatch)
      await persistMatchingTokenToStorage(startedMatch.matchingToken);
  };

  const updateMatchedHumanData = async (
    input: APIMatchedHuman.Update.Input,
  ) => {
    const matchingToken = await loadAndValidateMatchingToken();

    if (matchingToken) {
      await updateMatchedHuman(input);
    } else {
      navigate(RouteNames.Matching.Start.path);
    }
  };

  const updateMatchedHumanWorriesData = async (
    input: IUpdateMatchedHumanWorriesInput,
  ) => {
    const matchingToken = await loadAndValidateMatchingToken();

    if (matchingToken) {
      if (
        input.specialtiesThemeComboIds &&
        input.specialtiesThemeComboIds.length > 0
      ) {
        if (mounted.current) {
          await updateMatchedHumanWorries(input);
        }
      }
    } else {
      navigate(RouteNames.Matching.Start.path);
    }
  };

  const setSelectedMatch = (match: IMatch): void => {
    // noinspection JSIgnoredPromiseFromCall
    persistWithKeyToStorage(KEY_CHOSEN_PROFESSIONAL, match.professional);
    _setSelectedMatch(match);
  };

  const onMatchChosen = useCallback(
    async (match: IMatch) => {
      try {
        setSelectedMatch(match);
        await updateMatchedHumanData({
          chosenProfessionalId: match.professional.getID(),
        });
      } catch {
        // TODO: differentiate between coding errors and an existing e-mail.
        // Or maybe we can add an e-mail verifier while validating it.
        setToast({
          message: getGenericErrorMessage(translate),
          severity: "error",
        });
      }
    },
    [setSelectedMatch, updateMatchedHumanData, setToast],
  );

  const _login = async (accessToken: string, refreshToken: string) => {
    await removeMatchingTokenFromStorage();
    await login(accessToken, refreshToken);
  };

  // Adds the personal data to the user once they've selected their professional
  const createFullUser = useCallback(async () => {
    try {
      const { data } = await createPermanentHuman({
        contactForFeedback: formState.getValue("contactForFeedback"),
        email: formState.getValue(Email.getPath()),
        healthInsurance: formState.getValue(HealthInsurance.getPath()),
        password: formState.getValue(Password.getPath()),
        preferredLanguage: prepareOneLanguageForAPI(i18n.language),
        timezone: new Timezone().updateToCurrent().getValue(),
      });

      if (data?.createPermanentHuman) {
        const { accessToken, refreshToken } = data.createPermanentHuman;

        await _login(accessToken, refreshToken);
      }

      if (selectedMatch) {
        setUpNewConsultation(selectedMatch, selectedTimeSlot);
      }
    } catch (e) {
      console.error(e);
      setToast({
        message: getGenericErrorMessage(translate),
        severity: "error",
      });
    }
  }, [formState, setToast, selectedTimeSlot]);

  const storeConsultationId = (uuid: UUID) =>
    persistWithKeyToStorage(KEY_CONSULTATION_UUID, uuid);

  const setUpNewConsultation = useCallback(
    async (match: IMatch, slot?: TimeSlot) => {
      try {
        const startDate: moment.Moment = slot
          ? slot.getStartTime()
          : moment().seconds(0).milliseconds(0);

        const { data } = await createConsultation({
          variables: {
            input: {
              fromMatching: true,
              matchedHumanId: startMatchingData?.startMatching.matchedHumanId,
              otherPartyId: (match.professional as Professional).getID(),
              price: 0,
              scheduledFrom: moment(startDate).toDate(),
              scheduledTo: moment(
                startDate.clone().add("15", "minutes"),
              ).toDate(),
              status: new ConsultationStatus(
                slot ? "REQUESTED" : "WAITING",
              ).getValue(),
              type: ConsultationTypes.INTRO,
            },
          },
        });

        if (data?.createConsultation?.uuid) {
          await storeConsultationId(data.createConsultation.uuid);

          const redirectPath = slot
            ? RouteNames.Matching.Success.path
            : RouteNames.Matching.WaitingRoom.Wait.path.replace(
                ":uuid",
                data.createConsultation.uuid,
              );

          navigate(redirectPath);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [createConsultation, history, selectedMatch],
  );

  const startCall = useCallback(
    async (match: IMatch) => {
      try {
        if (match) {
          await setUpNewConsultation(match);
        } else {
          console.warn("Selected Match is undefined");
        }

        return;
      } catch (error) {
        console.error(error);
      }
    },
    [setUpNewConsultation],
  );

  const scheduleCall = useCallback(
    async (match: IMatch) => {
      try {
        if (selectedTimeSlot) {
          if (match) {
            await setUpNewConsultation(match, selectedTimeSlot);
          } else {
            console.warn("Selected Match or timeslot is undefined");
          }
        } else {
          startCall(match);
        }

        return;
      } catch (error) {
        console.error(error);
      }
    },
    [setUpNewConsultation, selectedTimeSlot],
  );

  const clearTimeSlot = () => setSelectedTimeSlot(undefined);

  const finishMatchingForExistingUser = useCallback(async () => {
    if (selectedMatch) {
      await setUpNewConsultation(selectedMatch, selectedTimeSlot);
    }

    if (organization?.getID()) {
      await updateHuman({ organizationId: organization.getID() });
    }

    await removeMatchingTokenFromStorage();
  }, [
    organization,
    removeMatchingTokenFromStorage,
    selectedMatch,
    selectedTimeSlot,
    setUpNewConsultation,
    updateHuman,
  ]);

  if (!formState || !ready || isFetchingSpecialties || isMatching)
    return <LoadingPage full />;

  return (
    <HumanMatchingContext.Provider
      value={{
        clearTimeSlot,
        createFullUser,
        fetchAndStoreMatchingToken,
        findMatches: executeFindMatches,
        finishMatchingForExistingUser,
        formIsValid,
        formState,
        fromPartnerPage,
        healthInsurance: formState.getValue(HealthInsurance.getPath()),
        isCreatingConsult,
        loadStoredWorriesInFormState,
        loading:
          isUpdatingHuman ||
          fetchingMe ||
          isFetchingSpecialties ||
          isUpdatingMatchedHumanWorries,
        matches: matches ?? null,
        next,
        onMatchChosen,
        organization,
        previous,
        scheduleCall,
        selectedMatch,
        selectedTimeSlot,
        setCurrentStep,
        setFromPartnerPage,
        setOrganization,
        setSelectedTimeSlot,
        specialties,
        startCall,
        step: currentStep,
        totalSteps: getStepsInProgressBar(),
        updateMatchedHumanData,
        updateMatchedHumanWorriesData,
      }}
    >
      {children}
    </HumanMatchingContext.Provider>
  );
}
