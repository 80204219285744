import { useMemo } from "react";
import { useLazyQuery } from "@apollo/client";
import Professional from "../../../data-model/types/professional/Professional";
import { FindMatchesQuery } from "../../__generated__/graphql";
import { graphql } from "../../__generated__";

const findMatchesQueries = graphql(`
  query FindMatches {
    findMatches {
      matches {
        availability
        isOnline
        professional {
          ...ProfessionalInfoForHuman
        }
        score
      }
    }
  }
`);

export type IMatch = NonNullable<
  NonNullable<FindMatchesQuery["findMatches"]>["matches"]
>[0] & {
  professional: Professional;
};

export default function useFindMatches() {
  const [execute, { loading, data, error }] = useLazyQuery(findMatchesQueries);

  const convertedMatches = useMemo(() => {
    if (!loading && data && data.findMatches && data.findMatches.matches) {
      return data.findMatches.matches.reduce((matches, match) => {
        matches.push({
          ...match,
          professional: new Professional(match.professional as any, true),
        });

        return matches;
      }, [] as IMatch[]);
    }
  }, [data, loading]);

  return {
    error,
    findMatches: execute,
    isMatching: loading,
    matches: convertedMatches,
  };
}
