import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  useTheme,
} from "@mui/material";
import BloomCirlcle from "./BloomCircle.svg";
import BloomCirlcleFilled from "./BloomCircleFilled.svg";
import BloomCheckCircle from "./BloomCheckCircle.svg";
import BloomCheckMark from "./BloomCheckMark.svg";
import BloomOutline from "./BloomCircleOutline.svg";
import BloomOutlineHover from "./BloomCircleOutlineGreen.svg";

interface BloomieCardProps {
  bodyChildren?: React.ReactNode;
  bodyText?: string;
  checked?: boolean;
  footerChildren?: React.ReactNode;
  imgUrl: string;
  logoImg?: boolean;
  onClick?: () => void;
  size?: "md" | "lg" | "xl";
  subTitle?: string;
  title?: string;
  type: "blank" | "outlined";
}

const BloomieCard = ({
  bodyText,
  size = "md",
  imgUrl,
  title,
  type,
  onClick,
  checked = false,
  logoImg,
  bodyChildren,
  footerChildren,
  subTitle,
}: BloomieCardProps) => {
  const theme = useTheme();

  return (
    <Card
      sx={{
        "&:hover .bodyText": {
          color: onClick
            ? checked
              ? undefined
              : theme.palette.black.main
            : undefined,
          transition: "color 0.5s ease-in-out",
        },
        "&:hover .outline": {
          backgroundImage: onClick
            ? checked
              ? undefined
              : `url(${BloomOutlineHover})`
            : undefined,
          transition: "background-image 0.5s ease-in-out",
        },
        "&:hover .titleText": {
          color: onClick
            ? checked
              ? undefined
              : theme.palette.green.dark
            : undefined,
          transition: "colo 0.5s ease-in-out",
        },
        ".MuiCardActionArea-focusHighlight": {
          background: "transparent",
        },
        background: "transparent",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        maxWidth: size === "md" ? "200px" : "250px",
        overflow: "visible",
        textAlign: "center",
        [theme.breakpoints.down(450)]: {
          maxWidth: "80%",
        },
      }}
    >
      <CardActionArea
        disableRipple
        onClick={() => {
          if (onClick) onClick();
        }}
        sx={{
          cursor: onClick ? "pointer" : "default",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box>
          {checked && (
            <Box
              sx={{
                left: size === "md" ? "77px" : "98px",
                position: "relative",
                top: size === "md" ? "142px" : "185px",
              }}
            >
              <Box
                component="img"
                src={BloomCheckCircle}
                sx={{
                  height: size === "md" ? undefined : "48px",
                  left: 0,
                  position: "absolute",
                  top: 0,
                  width: size === "md" ? undefined : "55px",
                }}
              />
              <Box
                component="img"
                src={BloomCheckMark}
                sx={{
                  height: size === "md" ? "20px" : "25px",
                  left: size === "md" ? 11 : 14.5, //prop needs to be specific for the check to be at the right position
                  position: "absolute",
                  top: size === "md" ? 11 : 14.5, //prop needs to be specific for the check to be at the right position
                  width: size === "md" ? "20px" : "25px",
                }}
              />
            </Box>
          )}

          <CardMedia
            className="outline"
            sx={{
              alignItems: "center",
              backgroundImage: `url(${
                checked || size === "xl"
                  ? BloomCirlcleFilled
                  : type === "outlined"
                  ? BloomOutline
                  : BloomCirlcle
              })`,
              backgroundRepeat: "no-repeat",
              display: "flex",
              height: size === "md" ? "170px" : "220px",
              justifyContent: "center",
              marginBottom: "9px",
              transition: "background-image 0.3s ease-out",
              width: size === "md" ? "190px" : "250px",
              [theme.breakpoints.down(350)]: {
                height: "160px",
                maxWidth: "180px",
              },
            }}
          >
            <Box
              component="img"
              src={imgUrl}
              sx={{
                maxHeight: size === "md" ? "120px" : logoImg ? "65%" : "125px",
                maxWidth: size === "md" ? "180px" : logoImg ? "65%" : "200px",
                minHeight: size === "md" ? undefined : "80px",
                minWidth: size === "md" ? undefined : "80px",
              }}
            />
          </CardMedia>
        </Box>

        <CardContent sx={{ padding: size === "md" ? undefined : 0 }}>
          {subTitle && (
            <Typography
              color={
                size === "xl"
                  ? theme.palette.green.main
                  : theme.palette.bloom.main
              }
            >
              {subTitle}
            </Typography>
          )}
          {title && (
            <Typography
              className="titleText"
              sx={{
                fontSize: size === "xl" ? "22px" : undefined,
                paddingY: "0.3rem",
                transition: "color 0.3s ease-out",
                [theme.breakpoints.down(450)]: {
                  fontSize: size === "xl" ? "18px" : "16px",
                },
              }}
              variant="h4"
            >
              {title}
            </Typography>
          )}
          {bodyText && (
            <Typography
              className="bodyText"
              sx={{
                "&:hover": {
                  color: onClick
                    ? checked
                      ? undefined
                      : theme.palette.black.main
                    : undefined,
                },
                color: onClick
                  ? checked
                    ? theme.palette.black.main
                    : theme.palette.black.light
                  : "#797272",
                fontSize: size === "xl" ? "14px" : "12px",
                fontWeight: 400,
                marginBottom: "1.5rem",
                transition: "color 0.3s ease-out",
              }}
            >
              {bodyText}
            </Typography>
          )}
          {bodyChildren && bodyChildren}
        </CardContent>
      </CardActionArea>
      {footerChildren && footerChildren}
    </Card>
  );
};

export default BloomieCard;
